@keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px) scale(0.95);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 5px theme('colors.purple.400/20');
    }
    50% {
      box-shadow: 0 0 20px theme('colors.purple.400/40');
    }
    100% {
      box-shadow: 0 0 5px theme('colors.purple.400/20');
    }
  }
  
  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes pulseGlow {
    0% {
      box-shadow: 0 0 0 0 theme('colors.purple.500/40');
    }
    70% {
      box-shadow: 0 0 0 10px theme('colors.purple.500/0');
    }
    100% {
      box-shadow: 0 0 0 0 theme('colors.purple.500/0');
    }
  }
  
  .nav-item-slide-in {
    animation: slideDown 0.3s ease-out forwards;
  }
  
  .nav-item-glow:hover {
    animation: glow 2s infinite;
  }
  
  .mobile-menu-button {
    animation: fadeInScale 0.3s ease-out;
  }
  
  .mobile-menu-button:active {
    animation: pulseGlow 0.5s cubic-bezier(0.4, 0, 0.6, 1);
  }
  
  /* Add animation delay for each nav item */
  .nav-item:nth-child(1) { animation-delay: 0.1s; }
  .nav-item:nth-child(2) { animation-delay: 0.2s; }
  .nav-item:nth-child(3) { animation-delay: 0.3s; }
  .nav-item:nth-child(4) { animation-delay: 0.4s; }
  .nav-item:nth-child(5) { animation-delay: 0.5s; }
  .nav-item:nth-child(6) { animation-delay: 0.6s; }
  .nav-item:nth-child(7) { animation-delay: 0.7s; }
  
  /* Responsive styles */
  @media screen and (max-width: 430px) {
    .nav-logo {
      font-size: 1rem;
    }
    .nav-items {
      font-size: 0.875rem;
    }
    
    .mobile-menu-button {
      padding: 0.5rem;
    }
  }
  
  @media screen and (min-width: 431px) and (max-width: 768px) {
    .nav-logo {
      font-size: 1.125rem;
    }
    .nav-items {
      font-size: 0.9375rem;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .nav-logo {
      font-size: 1.25rem;
    }
    .nav-items {
      font-size: 1rem;
    }
  }
  
  @media screen and (min-width: 1025px) {
    .nav-logo {
      font-size: 1.5rem;
    }
    .nav-items {
      font-size: 1.125rem;
    }
  }